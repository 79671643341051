import { keepPreviousData } from '@unifyapps/network/react-query';
import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { LookupByKeysRequest } from '@unifyapps/network/generated/models/lookupByKeysRequest';
import { LookupByKeysRequestType } from '@unifyapps/network/generated/models/lookupByKeysRequestType';
import type { MappedHits } from '@unifyapps/network/generated/models/mappedHits';

export type EntityItem = {
  id: string;
  name: string;
  description?: string;
  userCount?: number;
};

function useFetchEntitiesById<T extends 'ROLE_DEFINITION' | 'USER_GROUP'>(
  keys: string[],
  lookupType: T,
  options?: Record<string, unknown>,
): { items: EntityItem[]; isLoading: boolean; error: Error | null } {
  const lookupRequest: LookupByKeysRequest = {
    type: LookupByKeysRequestType.ByKeys,
    keys,
    lookupType,
    options,
  };

  const { data, isLoading, error } = useLookup(lookupRequest, {
    query: { enabled: keys.length > 0, placeholderData: keepPreviousData },
  });

  const objects = (data?.response as MappedHits | undefined)?.objects;

  return {
    items: keys.map((key) => objects?.[key]).filter((item) => item) as EntityItem[],
    isLoading,
    error,
  };
}

export function useFetchRolesById(roleIds: Set<string>) {
  const roleIdsArray = Array.from(roleIds);
  const { items: roles, isLoading, error } = useFetchEntitiesById(roleIdsArray, 'ROLE_DEFINITION');
  return { roles, isLoading, error };
}

export function useFetchUserGroupsById(userGroupIds: string[]) {
  const {
    items: userGroups,
    isLoading,
    error,
  } = useFetchEntitiesById(userGroupIds, 'USER_GROUP', { getUserCount: true });
  return { userGroups, isLoading, error };
}
