import {
  DEFAULT_PROJECT_ID,
  DEFAULT_PROJECT_ID_SLUG,
} from '@unifyapps/network/utils/consts/project';

export const PROJECT_DETAILS_PREFIX = '/p';
const ENABLED_PROJECT_ROUTES = [
  '/automations',
  '/automation-interfaces',
  '/api-platform',
  '/pipelines',
  '/interfaces',
  '/object',
  '/objects',
  '/campaigns',
  '/segments',
  '/connections',
  '/templates',
  '/env-variables',
  '/event-streams',
  '/decision-tables',
  '/code-snippets',
];

export const getAdaptedProjectSlug = (
  projectSlug?: string | undefined | null | number,
  projectId?: number,
) => {
  let adaptedProjectSlug = projectSlug;

  if (projectSlug === DEFAULT_PROJECT_ID_SLUG && projectId) {
    adaptedProjectSlug = `0${projectId}`; // we have to prefix projectId with 0 if twe are currently outside any project, but opening an entity mapped to a project
  }

  return adaptedProjectSlug;
};

export const getAdaptedProjectsRoute = (
  route: string,
  projectSlug?: string | undefined | null | number,
) => {
  if (route.startsWith(`${PROJECT_DETAILS_PREFIX}/`)) {
    return route;
  }

  const shouldPrefix = ENABLED_PROJECT_ROUTES.some((r) => route.startsWith(r));

  if (!shouldPrefix) {
    return route;
  }

  return `${PROJECT_DETAILS_PREFIX}/${projectSlug || DEFAULT_PROJECT_ID}${route}`;
};
